import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  const token = new URLSearchParams(window.location.search).get('claim_token');

  return {
    headers: {
      ...headers,
      'x-claim-token': token,
    },
  };
});

export default authLink;
