import { ApolloClient, ApolloLink } from '@apollo/client';

import cache from './cache';
import { authLink, errorLink, httpLink } from './links';

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
  connectToDevTools: true,
});

export default client;
